<template lang="pug">
.cards
  //- pre {{ invoice }}
  .cards__body
      AppCard(v-if="invoice")
        AppRibbon(v-if="invoice.payment_state === 'paid'" type="success" :label="$t('invoices.paid')")
        .invoice__name {{ invoice.name }}
        AppRow.mb-4
          AppDescription(:label="$t('invoices.partner_id')" :description="invoice.partner_id[1]")
          AppDescription(:label="$t('invoices.servicio_id')" :description="invoice.servicio_id[1]")
        AppRow.mb-4
          AppDescription(:label="$t('invoices.invoice_date')" :description="dateFormatter(invoice.invoice_date)")
          AppDescription(:label="$t('invoices.invoice_payment_term_id')" :description="invoice.invoice_payment_term_id[1]")
        AppRow.mb-4
          AppDescription(:label="$t('invoices.amount_total')" :description="numberFormatter(invoice.amount_total)")
          AppDescription(:label="$t('invoices.amount_residual')" :description="numberFormatter(invoice.amount_residual)")
        AppRow.mb-4
          AppDescription(:label="$t('invoices.state')" :description="invoice.state")
        div(v-if="!service.subscription_status && (invoice.payment_state === 'not_paid' || invoice.payment_state === 'partial') && invoice.payment_status == false")
          AppCheckbox(v-model="checkvalue" :label="$t('invoices.confirm')" )
          AppRow
            AppSubscriptionForm(
              :invoice="invoice" 
              :service="service" 
              :name="invoice.name" 
              :disabled="!checkvalue"
              v-if="allowSubscription && invoice.tipo_factura_lead !== 'INSTALACION RESIDENCIAL'"
            )
            AppCheckoutForm(
              :invoice="invoice" 
              :name="invoice.name" 
              :ShowCheckTerms="false" 
              :disabled="!checkvalue"
            )
        AppAlert.mb-4(v-if="service.subscription_status" :title="$t('payment.subscription.title')" :subtitle="$t('payment.subscription.subtitle')" type="info")
        //- router-link(:to="{name: 'InvoicesPay', params: { id: invoice.id } }" v-slot="{ navigate }" custom)
        //-   AppButton(:label="$t('invoices.pay')" v-if="invoice.payment_state === 'not_paid'" @click="navigate" :disabled="!isLastInvoice")
      AppCard.mt-4.text-center
        span.label
          | {{$t('quickpayment.info')}}
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
// import { useI18n } from "vue-i18n";

import { axios } from "@/utils/axios";
import { dateFormatter, numberFormatter } from "@/utils/formatters";
import { store } from "@/store";

import AppCheckoutForm from "@/components/Payment/AppCheckoutForm";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppSubscriptionForm from "@/components/Payment/AppSubscriptionForm";
import AppAlert from "@/components/AppAlert";
import AppCard from "@/components/AppCard";
import AppButton from "@/components/AppButton";
import AppDescription from "@/components/AppDescription";
import AppRibbon from "@/components/AppRibbon";
import AppRow from "@/components/AppRow";

export default {
  components: {
    AppAlert,
    AppCard,
    AppButton,
    AppDescription,
    AppRibbon,
    AppRow,
    AppCheckoutForm,
    AppSubscriptionForm,
    AppCheckbox,
  },
  setup() {
    // const { t } = useI18n();
    const checkvalue = ref(false);
    const route = useRoute();
    const invoice = ref(null);
    const service = ref(null);
    const loading = ref(true);
    // const isLastInvoice = ref(true);
    const allowSubscription = ref(false);

    // const getLastInvoice = async (service_id) => {
    //   const { id } = route.params;
    //   const { data } = await axios.get(
    //     `invoicesodoo/list/${service_id}?type=not_paid&limit=1`
    //   );

    //   return data.payload[0] && data.payload[0].id == id;
    // };

    const getValidateInvoicesForSubscription = async (service_id) => {
      const { data } = await axios.get(
        `invoicesodoo/list/${service_id}?type=not_paid&limit=0`
      );

      // data.payload.length
      // console.log("----------");
      // console.log(data.payload);
      // console.log("----------");
      const today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      let complete_date = year + "-" + month + "-" + day;
      // data.payload.invoice_date_due > year + "-" + month + "-" + day

      let countInvoicesNotPaid = data.payload.length;
      let validateInvoicesExpirationDates = data.payload.filter(
        (inv) => inv.invoice_date_due >= complete_date
      );

      // console.log(countInvoicesNotPaid);
      // console.log(validateInvoicesExpirationDates);

      if (
        countInvoicesNotPaid == 1 &&
        validateInvoicesExpirationDates.length == 1
      ) {
        return true;
      }

      return false;
    };

    onMounted(async () => {
      try {
        const { id } = route.params;
        store.APP_LOADING = true;
        store.I118N_TITLE = false;
        store.APP_TITLE = "";

        const { data } = await axios.get(`invoicesodoo/${id}`);
        invoice.value = data.payload[0];
        service.value = data.service[0];

        // if (invoice.value.payment_state === "not_paid") {
        //   isLastInvoice.value = await getLastInvoice(
        //     invoice.value.servicio_id[0]
        //   );
        // }
        allowSubscription.value = await getValidateInvoicesForSubscription(
          invoice.value.servicio_id[0]
        );
        store.APP_TITLE = invoice.value.name;
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    });
    return {
      // isLastInvoice,
      invoice,
      loading,
      dateFormatter,
      numberFormatter,
      service,
      checkvalue,
      getValidateInvoicesForSubscription,
      allowSubscription,
    };
  },
};
</script>

<style lang="scss" scoped>
.invoice__name {
  @apply text-xs;
  @apply text-bluegray-400;
  @apply mb-2;
  @apply font-medium;
}

.label {
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>
